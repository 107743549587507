(<template>
  <div class="one-staffing">
    <div class="one-staffing__container">
      <h1 class="one-staffing__title">{{ $gettext('Thank you for your order') }}</h1>
      <p class="one-staffing__description">{{ $gettext('We have received your order. You will be contacted as soon as possible, so that together we can find the solution that is best to meet the need.') }}</p>
      <p class="one-staffing__description"
         v-html="description"></p>
      <summary-info :summary-list="summaryList"
                    class="one-staffing__summary-info" />
      <div class="one-staffing__booking-buttons">
        <router-link :to="$makeRoute({name: 'BuyerPostInterpretation'})"
                     replace
                     class="sk-btn sk-btn--default one-staffing__booking-btn">{{ $gettext('Book an interpreter') }}</router-link>
        <router-link :to="$makeRoute({name: 'BuyerPostStaffing'})"
                     replace
                     class="sk-btn sk-btn--default one-staffing__booking-btn">{{ $gettext('Book a vikar') }}</router-link>
      </div>
    </div>
  </div>
</template>)

<script>
  import {mapState} from 'vuex';
  import SummaryInfo from '@/components/booking_components/SummaryInfo';
  import helpers from '@/helpers';

  export default {
    components: {
      'summary-info': SummaryInfo
    },
    computed: {
      ...mapState('EnterpriseStore', {
        employees: (state) => state.employees || []
      }),
      ...mapState('OneStaffingStore', {
        staffingJob: (state) => state.staffingJob
      }),
      description() {
        const template = this.$gettext('If you need to be contacted by us \'here and now\', call <a href="tel:%{tel}" class="sk-link sk-link--default js-copy-to-clipboard">%{tel}</a>.');
        return this.$gettextInterpolate(template, {tel: this.$getString('domainData', 'supportStaffingPhone')});
      },
      requirement() { return this.staffingJob?.requirement || {}; },
      info() { return this.staffingJob?.info || {}; },
      foreignLang() {
        return this.requirement.languageToId ? this.$getLangName(this.requirement.languageToId) : '';
      },
      dates() {
        const dates = this.staffingJob?.assignmentDates;
        return dates?.map((date = {}) => `${date.date} ${date.startTime} - ${date.finishTime}`) || '';
      },
      departmentName() { return this.requirement.department?.name || ''; },
      attenders() { return this.requirement.attenders || []; },
      bookerUid() { return this.requirement.bookerUid; },
      details() { return this.requirement.translatorInformation; },
      gender() {
        const gender = this.requirement.sex;
        const gendersList = this.$helperData.getSelectGenders(this) || [];
        const genderObj = gendersList?.find((g) => g.id == gender) || {};
        return genderObj.name;
      },
      booker() {
        const booker = this.employees.find((att) => att.uid === this.bookerUid) || {};
        return booker.firstName || booker.lastName ? `${booker.firstName} ${booker.lastName}` : booker.email || '';
      },
      caseNumber() { return this.info.caseNumber; },
      bookingRef() { return this.info.bookingReference; },
      paymentBookingRef() { return this.info.paymentBookingReference; },
      contactPersonInfo() {
        const {name, phone} = this.requirement.contactAttributes?.person || {};
        return name && phone ? `${name} / ${phone}` : '';
      },
      contactAddressInfo() {
        const contactAddressInfo = this.requirement.contactAttributes?.address || {};
        return helpers.getJobInfo.savedAddressName(contactAddressInfo);
      },
      categoryName() {
        const jobCategoryItem = this.info.jobCategoryItem || {};
        const categoryName = jobCategoryItem.jobCategory?.name || '';
        const subCategoryName = jobCategoryItem.name || '';
        return categoryName || subCategoryName ? `${categoryName} - ${subCategoryName}` : '';
      },
      subject() { return this.info.subject; },
      chosenAttenders() {
        return this.attenders?.map((attender) => {
          return attender.firstName || attender.lastName ? `${attender.firstName} ${attender.lastName}` : attender.email;
        }) || '';
      },
      summaryList() {
        return [{
          lines: [
            {text: this.$gettext('Booker'), value: this.booker},
            {text: this.$gettext('Email'), value: this.info.ownerEmail},
            {text: this.$gettext('Phone'), value: this.info.ownerPhone},
            {text: this.$store.getters['UserInfoStore/labelForBookingRef'] || this.$gettext('Booking ref.'), value: this.bookingRef},
            {text: this.$store.getters['UserInfoStore/labelForPaymentBookingRef'] || this.$gettext('Attestant BID'), value: this.paymentBookingRef},
            {text: this.$gettext('Department'), value: this.departmentName},
            {text: this.$gettext('Other contact person (gets copy)'), value: this.chosenAttenders},
            {text: this.$store.getters['UserInfoStore/labelForCaseNumber'] || this.$gettext('Case number'), value: this.caseNumber}
          ]
        }, {
          lines: [
            {text: this.$gettext('Foreign language'), value: this.foreignLang},
            {text: this.$gettext('Date(s)'), value: this.dates},
            {text: this.$gettext('Category'), value: this.categoryName},
            {text: this.$gettext('Subject'), value: this.subject},
            {text: this.$gettext('Details'), value: this.details},
            {text: this.$gettext('Gender'), value: this.gender},
            {text: this.$gettext('Contact person info'), value: this.contactPersonInfo},
            {text: this.$gettext('Contact address info'), value: this.contactAddressInfo}
          ]
        }];
      }
    },
    beforeMount() {
      if (!this.staffingJob) { this.$router.replace(this.$makeRoute({name: 'BuyerHome'})); }
    }
  };
</script>

<style scoped>
  .one-staffing {
    margin: 30px 40px;
  }

  .one-staffing__container {
    width: 100%;
    max-width: 690px;
    margin: auto;
    padding: 40px 40px 30px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 9px 0 rgba(0, 34, 102, 0.3);
  }

  .one-staffing__title {
    color: #ff5b24;
    color: var(--color-secondary);
    font-weight: normal;
    font-size: 22px;
  }

  .one-staffing__description {
    margin-top: 10px;
  }

  .one-staffing__summary-info {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.79;
  }

  .one-staffing__booking-buttons {
    display: flex;
    margin: 0 -10px;
  }

  .one-staffing__booking-btn {
    display: block;
    width: 100%;
    max-width: 220px;
    margin: 10px;
  }

  @media (max-width: 767px) {
    .one-staffing {
      margin: 30px 15px;
    }

    .one-staffing__container {
      padding: 20px 10px;
    }
  }

  @media (max-width: 550px) {
    .one-staffing__booking-buttons {
      flex-wrap: wrap;
    }

    .one-staffing__booking-btn {
      max-width: 100%;
      margin-top: 0;
    }
  }
</style>
